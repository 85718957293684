import { HttpParams } from '@angular/common/http';

export interface Pagination {
  page: number;
  size: number;
  total?: number;
  sort?: string[];
}

export class PaginationPresenter implements Pagination {
  public page: number;
  public size: number;
  public total?: number;
  public sort?: string[];

  public constructor(init?: Partial<Pagination>) {
    Object.assign(this, init);
  }

  public pageShowingTo(): number {
    return Math.min((this.page + 1) * this.size, this.total ? this.total : 0);
  }

  public pageShowingFrom(): number {
    return this.total && this.total < 1 ? 0 : this.page * this.size + 1;
  }

  static from<T extends Pagination>(this: new () => T, data: Partial<T>): T {
    const o = new this(); // no-arg constructor
    Object.assign(o, data);
    return o;
  }
}

export interface Search {
  query?: string | null;
  role?: string | null;
}

export interface SearchWithPagination extends Search, Pagination {}

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });

    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }

  return options;
};
