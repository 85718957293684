// These constants are injected via webpack DefinePlugin variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

declare const __DEBUG_INFO_ENABLED__: boolean;
declare const __VERSION__: string;

export const APP = 'vbeta'; // process.env.APP;
export const VERSION = ''; // process.env.VERSION;
export const NODE_ENV = ''; // process.env.NODE_ENV;
export const DEBUG_INFO_ENABLED = false; // Boolean(process.env.DEBUG_INFO_ENABLED);
export const SERVER_API_URL = ''; // process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = ''; // process.env.BUILD_TIMESTAMP;
export const IS_LOCAL = window.location.host.includes('localhost');
export const IS_DEVELOPMENT = true; // Boolean(process.env.IS_DEVELOPMENT);
export const IS_PROD = !window.location.host.includes('localhost') && !window.location.host.includes('-');
