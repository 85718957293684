import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: any): any {
    if (value !== 0) {
      return this.decimalPipe.transform(value, '1.', 'en-DK');
    } else {
      return '0';
    }
  }
}
