import { Component, AfterViewInit, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@cupoui/core/auth/account.service';
import { LoginService } from '@cupoui/core/login/login.service';
import { SettingsService } from '@cupoui/core/services/settings.service';
import { NeedHelpLoggingInModalComponent } from '@cupoui/shared/components/need-help-logging-in-modal/need-help-logging-in-modal.component';

@Component({
  selector: 'jhi-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('username', { static: false }) username?: ElementRef;
  @ViewChild('oneTimePin', { static: false }) oneTimePin?: ElementRef;

  authenticationError = false;
  serviceSessionError: string | null;

  oneTimePinRequested = false;

  isDevelopment = false;

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    oneTimePin: ['', [Validators.required]],
    rememberMe: [false]
  });

  private subscription: Subscription;

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private settingService: SettingsService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.isDevelopment = this.settingService.isLocal();

    this.serviceSessionError = localStorage.getItem('service-session-error');
    if (this.serviceSessionError) {
      localStorage.removeItem('service-session-error');
    }
    this.subscription = this.loginForm.controls.oneTimePin.valueChanges.subscribe((oneTimePin: string) => {
      if (oneTimePin?.length === 6) {
        this.loginForm.controls.oneTimePin.patchValue(oneTimePin.toUpperCase().replace('S-', ''), { emitEvent: false });
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.username) {
      this.username.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  cancel(): void {
    this.authenticationError = false;
    this.loginForm.patchValue({
      username: '',
      password: '',
      oneTimePin: ''
    });
    this.activeModal.dismiss('cancel');
  }

  get mailMyPinEnabled(): boolean {
    const values = this.loginForm.value;
    return !!values.username && !!values.password;
  }

  requestOneTimePin(): void {
    this.oneTimePinRequested = false;
    this.authenticationError = false;
    const values = this.loginForm.value;
    this.accountService.requestOneTimePin(values.username).subscribe(() => {
      this.oneTimePinRequested = true;
      if (this.oneTimePin) {
        this.oneTimePin.nativeElement.focus();
      }
    },
    err => {
        if (err.status !== 200) {
                if (this.settingService.isProduction()) {
                    localStorage.setItem('service-session-error','');
                } else {
                    localStorage.setItem('service-session-error', 'Problem contacting servers. '+err?.url+' - ' + err?.message);
                }
          }
    });
  }

  get signInEnabled(): boolean {
    const values = this.loginForm.value;
    return !!values.username && !!values.password && !!values.oneTimePin;
  }

  login(): void {
    localStorage.setItem('service-session-error','');
    this.loginService
      .login({
        username: this.loginForm.get('username')!.value,
        password: this.loginForm.get('password')!.value,
        oneTimePin: this.loginForm.get('oneTimePin')!.value,
        rememberMe: this.loginForm.get('rememberMe')!.value
      })
      .subscribe(
        () => {
          this.authenticationError = false;
          localStorage.setItem('service-session-error','');
          this.activeModal.close();
          if (
            this.router.url === '/account/register' ||
            this.router.url.startsWith('/account/activate') ||
            this.router.url.startsWith('/account/reset/')
          ) {
            this.router.navigate(['']);
          }
        },
        err => {
          this.authenticationError = true;
          this.oneTimePinRequested = false;
          this.loginForm.patchValue({ oneTimePin: '' });
          if (err.status === 500) {
                if (this.settingService.isProduction()) {
                    localStorage.setItem('service-session-error','');
                } else {
                    localStorage.setItem('service-session-error','Problem contacting servers. '+err?.url+' - ' + err?.message);
                }
          }
        }
      );
  }

  register(): void {
    this.activeModal.dismiss('to state register');
    this.router.navigate(['/account/register']);
  }

  requestResetPassword(): void {
    this.activeModal.dismiss('to state requestReset');
    this.router.navigate(['/auth/reset', 'request']);
  }

  needHelpLoggingIn(): void {
    this.modalService.open(NeedHelpLoggingInModalComponent, {
      size: 'xl'
    });
  }

  quickSignIn(value: string): void {
    this.loginForm.patchValue({
      username: value,
      password: value,
      oneTimePin: '1234',
      rememberMe: true
    });
    this.login();
  }
}
