import { CaseScreening } from './caseScreening';
import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { UserAdapter } from './user.model';
import { PhoneConversation } from './phoneConversation';
import { CaseStakeholder } from './caseStakeholder';
import { SimpleCaseStakeholder } from './simpleCaseStakeholder';
import { SimpleIncident } from './SimpleIncident';
import { ChannelType } from './enumerations/channelType';
import { StakeholderTypeEnum } from './enumerations/stakeholder-type.enum';
import { HandlingArea, HandlingAreaAdapter } from '@cupoui/shared/model/handling-area';
import { MedicalPreAssessmentDecisionEnum } from './enumerations/medical-preassessment-enum';

export class Case {
  team: string;
  cu: string;
  product: string;
  id: string;
  endUserName: string;
  sex: string;
  age: string;
  incidentId: number;
  incidentCountry: string;
  incidentCity: string;
  cause: string;
  assignment: string;
  acStatus: string;
  channelType: ChannelType;
  countryCode: string;
  incidentTimeZone: number;
  currentTimeZone: number;
  caseCreatedDate: Date | null;
  caseCreatedDateAsISOString: string;
  lastHandled: Date | null;
  timeSpendInMinutes: number;
  phoneConversation: PhoneConversation[];
  insuranceCheckTime: Date | null;
  insuranceCheckMethod: string;
  insuranceCheckUser: string;
  privacyNoticeTime: Date | null;
  privacyNoticeChannel: string;
  privacyNoticeSendTo: string;
  caseClosedTime: Date | null;
  caseClosedHandler: string;
  caseNumber: string;
  caseStakeholders: CaseStakeholder[];
  simpleCaseStakeholders: SimpleCaseStakeholder[];
  incident: SimpleIncident;
  enduserId: string;
  policyHolderId: string;
  reporterId: string;
  caseScreening: CaseScreening;
  customerProfileId: number;
  handlingAreas: HandlingArea[];
  lastAcknowledged: Date | null;
  lastAcknowledgedBy: string;
  caseTypeCode: string;
  caseTypeTranslated: string;
  validatedOn: Date | null;
  medicalPreAssessmentDecision: MedicalPreAssessmentDecisionEnum;

  public constructor(init?: Partial<Case>) {
    Object.assign(this, init);
  }

  public static extractStakeholderName(case1: Case, stakeholderType: StakeholderTypeEnum): string | null {
    let name: string | null = null;

    case1.caseStakeholders.forEach(stakeHolder => {
      if (stakeHolder.stakeholderType === stakeholderType.toString()) {
        case1.simpleCaseStakeholders.forEach(simpleStakeHolder => {
          if (simpleStakeHolder.id === stakeHolder.stakeholderId) {
            name = simpleStakeHolder.firstName + ' ' + simpleStakeHolder.surname;
          }
        });
      }
    });

    return name;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseAdapter implements Adapter<Case> {
  constructor(private userAdapter: UserAdapter, private handlingAreaAdapter: HandlingAreaAdapter) {}

  adapt(item: any): Case {
    return new Case({
      team: '', // Is not used yet, it will become the product team later.
      cu: item.cu,
      product: item.product,
      id: item.id,
      endUserName: item.endUserName,
      sex: item.sex,
      age: item.age,
      incidentId: item.incidentId,
      incidentCountry: item.incidentCountry,
      incidentCity: item.incidentCity,
      cause: item.cause,
      assignment: item.assignee,
      acStatus: item.acStatus,
      channelType: item.channelType,
      countryCode: item.countryCode,
      incidentTimeZone: item.incidentTimezone,
      currentTimeZone: item.currentTimezone,
      caseCreatedDate: item.caseCreatedDate ? new Date(item.caseCreatedDate) : null,
      caseCreatedDateAsISOString: item.caseCreatedDateAsISOString,
      lastHandled: item.lastHandled ? new Date(item.lastHandled) : null,
      timeSpendInMinutes: item.timeSpendInMinutes,
      phoneConversation: item.phoneConversation,
      insuranceCheckTime: item.insuranceCheckTime ? new Date(item.insuranceCheckTime) : null,
      insuranceCheckMethod: item.insuranceCheckMethod,
      insuranceCheckUser: item.insuranceCheckUser,
      privacyNoticeTime: item.privacyNoticeTime ? new Date(item.privacyNoticeTime) : null,
      privacyNoticeChannel: item.privacyNoticeChannel,
      privacyNoticeSendTo: item.privacyNoticeSendTo,
      caseClosedTime: item.caseClosedTime ? new Date(item.caseClosedTime) : null,
      caseClosedHandler: item.caseClosedHandler,
      caseNumber: item.caseNumber,
      caseStakeholders: item.caseStakeholders,
      simpleCaseStakeholders: item.stakeholders,
      incident: item.incident,
      enduserId: item.enduserId,
      policyHolderId: item.policyHolderId,
      reporterId: item.reporterId,
      caseScreening: item.caseScreening,
      handlingAreas: item.handlingAreas.map((handlingAreaItem: any) => this.handlingAreaAdapter.adapt(handlingAreaItem)),
      lastAcknowledged: item.lastAcknowledged ? new Date(item.lastAcknowledged) : null,
      lastAcknowledgedBy: item.lastAcknowledgedBy,
      caseTypeCode: item.caseTypeCode,
      caseTypeTranslated: item.caseTypeTranslated,
      validatedOn: item.validatedOn ? new Date(item.validatedOn) : null,
      medicalPreAssessmentDecision: item.medicalPreAssessmentDecision !== null ? item.medicalPreAssessmentDecision : null
    });
  }
}
