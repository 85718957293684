import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '@cupoui/core/services/http.service';
import { Login } from '@cupoui/core/login/login.model';
// import { SERVER_API_URL } from '../../app.constants';

export const LOGOUT_URL = 'auth/logout';
// export const LOGOUT_URL = SERVER_API_URL + 'auth/logout';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(private httpService: HttpService, private http: HttpClient) {}

  login(credentials: Login): Observable<any> {
    return this.httpService.post('auth/login', credentials);
  }

  logout(): Observable<{}> {
    return this.httpService.post('auth/logout', null);
//    return this.http.post(LOGOUT_URL, null);
  }
}
