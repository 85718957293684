import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class Country {
  code: string | null;
  url: string;
  countryName: string;
  name: string;
  phonePrefix: string;

  public constructor(init?: Partial<Country>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {
  adapt(item: any): Country {
    return new Country({
      code: item.countryCodeAlpha2,
      countryName: item.countryName,
      name: item.name,
      url: `/flag-icons/${item.alpha2Code}.svg`.toLocaleLowerCase(),
      phonePrefix: item.phonePrefix
    });
  }
}
