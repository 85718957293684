<div class="password-reset-container">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <h3 jhiTranslate="reset.request.title">Reset your password</h3>

            <jhi-alert-error></jhi-alert-error>

            <div class="alert alert-warning" *ngIf="!success">
                <span jhiTranslate="reset.request.messages.info">Enter the login or email address you want to reset.</span>
            </div>

            <div class="alert alert-success" *ngIf="success">
                <span jhiTranslate="reset.request.messages.success">Check your emails for details on how to reset your password.</span>
            </div>

            <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()" [formGroup]="resetRequestForm">
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.reset.label">Login or email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="{{ 'global.form.reset.placeholder' | translate }}"
                           formControlName="email" #email>

                    <div *ngIf="resetRequestForm.get('email')!.invalid && (resetRequestForm.get('email')!.dirty || resetRequestForm.get('email')!.touched)">
                        <small class="form-text text-danger"
                               *ngIf="resetRequestForm.get('email')?.errors?.required"
                               jhiTranslate="global.messages.validate.email.required">
                            Your login or email is required.
                        </small>

                        <small class="form-text text-danger"
                               *ngIf="resetRequestForm.get('email')?.errors?.email"
                               jhiTranslate="global.messages.validate.email.invalid">
                            Your login or email is invalid.
                        </small>

                        <small class="form-text text-danger"
                               *ngIf="resetRequestForm.get('email')?.errors?.minlength"
                               jhiTranslate="global.messages.validate.email.minlength">
                            Your login or email is required to be at least 4 characters.
                        </small>

                        <small class="form-text text-danger"
                               *ngIf="resetRequestForm.get('email')?.errors?.maxlength"
                               jhiTranslate="global.messages.validate.email.maxlength">
                            Your login or email cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <button type="submit" class="primary" mat-stroked-button jhiTranslate="reset.request.form.button" [disabled]="resetRequestForm.invalid">Reset</button>
                </div>
            </form>
        </div>
    </div>
</div>
