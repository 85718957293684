import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, of, Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-reload-footer',
  templateUrl: './reload-footer.component.html',
  styleUrls: ['./reload-footer.component.scss']
})
export class ReloadFooterComponent implements OnInit {
  count = 0;
  newVersion = false;

  private lastModified: String;
  private readonly CONFIG_URL = 'build.txt';
  private headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  });

  private subscription: Subscription;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.subscription = interval(100 * 1000)
      .pipe(
        mergeMap(() =>
          this.http.get(this.CONFIG_URL, { responseType: 'text', headers: this.headers }).pipe(catchError(error => of('-404-')))
        )
      )
      .subscribe(data => {
        this.count++;
        this.checkForNewVersion(data?.replace('"', ''));
      });
  }

  reloadAppClick(): void {
    this.reload();
  }

  private checkForNewVersion(newValue: string): void {
    if (newValue !== '-404-') {
      if (!this.lastModified) {
        this.lastModified = newValue;
      } else {
        if (newValue && this.lastModified !== newValue) {
          this.subscription.unsubscribe();
          this.newVersion = true;
        }
      }
    }
  }

  private reload(): void {
    window.location.reload();
  }
}
