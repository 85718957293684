import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class GenericDictionary<T> {
  id: T;
  name: string;

  public constructor(id: T, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class NumberDictionary extends GenericDictionary<number> {}

export class Dictionary extends GenericDictionary<string> {}

@Injectable({
  providedIn: 'root'
})
export class DropdownAdapter implements Adapter<Dictionary> {
  adapt(item: string): Dictionary {
    return new Dictionary(item, item);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TeamDropdownAdapter implements Adapter<Dictionary> {
  adapt(item: any): Dictionary {
    return new Dictionary(item.teamId, item.teamName);
  }
}
