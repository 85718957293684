<router-outlet class="header" name="header"></router-outlet>
<mat-sidenav-container fxFill>
    <mat-sidenav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'" fixedTopGap="50" fxFlex="240px" #sidenav>
        <router-outlet name="sidenav"></router-outlet>
        <!--app-sidenav (click)="mobileQuery.matches ? sidenav.close() : ''"></app-sidenav-->
    </mat-sidenav>
    <mat-sidenav-content fxFlex>
        <div id="main-container" class="main-container" fxFill>
            <div class="page-info-container" *ngIf="!isHomeActive()" style="display: none">
                <fa-icon icon="info-circle" size="lg" class="text-primary mr-1"></fa-icon>
                <span jhiTranslate="global.pageInfo">What does this page do?</span>
            </div>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-footer *ngIf="isAuthenticated() && (!isProduction || isAdmin())"></app-footer>
<app-reload-footer></app-reload-footer>
