import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '@cupoui/core/auth/account.service';
import { SettingsService } from '@cupoui/core/services/settings.service';
import { LoginModalService } from '@cupoui/core/login/login-modal.service';
import { StateStorageService } from './state-storage.service';
import { Account } from '@cupoui/core/user/account.model';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService  {
  constructor(
    private router: Router,
    private loginModalService: LoginModalService,
    private accountService: AccountService,
    private settingService: SettingsService,
    private stateStorageService: StateStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authorities = route.data['authorities'];
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Observable<boolean> {
    return this.accountService.identity().pipe(
      map(account => {
        if (!authorities || authorities.length === 0) {
          return true;
        }

        if (account) {
          if (!this.accessGranted(account)) {
            if (this.settingService.getApp()?.includes('SUPO')) {
              if (this.settingService.isProduction()) {
                window.location.href = 'https://customerportal.sos.eu/home';
              } else {
                window.location.href = 'https://customerportal-test.sos.eu/home';
              }
            } else if (this.settingService.getApp()?.includes('CUPO')) {
              if (this.settingService.isProduction()) {
                window.location.href = 'https://supplierportal.sos.eu/home';
              } else {
                window.location.href = 'https://supplierportal-test.sos.eu/home';
              }
            }
            return false;
          }
          const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
          if (hasAnyAuthority) {
            return true;
          }
          if (isDevMode()) {
            console.error('User has not any of required authorities: ', authorities);
          }
          this.router.navigate(['accessdenied']);
          return false;
        }

        this.stateStorageService.storeUrl(url);
        this.router.navigate(['/auth/sign-in']);
        return false;
      })
    );
  }

  private accessGranted(account: Account): boolean {
    if (this.settingService.getApp()?.includes('SUPO') && account.customerRoles.length && account.supplierRoles.length === 0) {
      return false;
    } else if (this.settingService.getApp()?.includes('CUPO') && account.supplierRoles.length && account.customerRoles.length === 0) {
      return false;
    } else {
      return true;
    }
  }
}
