import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { SharedLibsModule } from './shared-libs.module';
import { FindLanguageFromKeyPipe, FindLanguageFlagFromKeyPipe } from './language/find-language-from-key.pipe';
import { TranslateDirective } from './language/translate.directive';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { LoginModalComponent } from './login/login.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { InputPatternDirective } from './directives/input-pattern.directive';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { InputComponent } from './components/input/input.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DropDownInputComponent } from './components/drop-down-input/drop-down-input.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { InputCalendarComponent } from './components/input-calendar/input-calendar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { FormControlErrorMessageComponent } from './components/form-control-error-message/form-control-error-message.component';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { InputLocationComponent } from './components/input-location/input-location.component';
import { MapComponent } from './components/map/map.component';
import { InputAutoCompleteComponent } from './components/input-autocomplete/input-autocomplete.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { OpenInvoicePdfComponent } from './components/open-invoice-pdf/open-invoice-pdf.component';

import { TableauComponent } from './components/tableau/tableau.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { TermsOfUseModalComponent } from './components/terms-of-use-modal/terms-of-use-modal.component';
import { NeedHelpLoggingInModalComponent } from './components/need-help-logging-in-modal/need-help-logging-in-modal.component';
import { EnvironmentRibbonComponent } from './components/environment-ribbon/environment-ribbon.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MomentPipe } from './pipes/moment-pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { VideoStreamModalComponent } from './components/video-stream-modal/video-stream-modal.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { DropDownMultiComponent } from './components/drop-down-multi/drop-down-multi.component';
import { DropDownStakeholderWithIconComponent } from './components/drop-down-stakeholder-with-icon/drop-down-stakeholder-with-icon.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { ReloadFooterComponent } from './components/reload-footer/reload-footer.component';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { HeaderComponent } from '../layouts/header/header.component';
import { ActionBarComponent } from '../layouts/action-bar/action-bar.component';
import { MultipleSelectDropdownComponent } from '@cupoui/shared/components/multiple-select-dropdown/multiple-select-dropdown.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    MaterialModule,
    SharedLibsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgMultiSelectDropDownModule,
  ],
  declarations: [
    FindLanguageFromKeyPipe,
    FindLanguageFlagFromKeyPipe,
    TranslateDirective,
    AlertComponent,
    AlertErrorComponent,
    LoginModalComponent,
    HasAnyAuthorityDirective,
    InputPatternDirective,
    ErrorMessageComponent,
    InputComponent,
    InputCalendarComponent,
    InputAutoCompleteComponent,
    TextBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    PaginationComponent,
    DropDownInputComponent,
    ModalDialogComponent,
    SpinnerComponent,
    HeaderComponent,
    NavbarComponent,
    ActionBarComponent,
    FormErrorMessageComponent,
    FormControlErrorMessageComponent,
    InputDateTimeComponent,
    InputLocationComponent,
    MapComponent,
    SafeHtmlPipe,
    RouteTransformerDirective,
    OpenInvoicePdfComponent,
    TableauComponent,
    TermsOfUseComponent,
    TermsOfUseModalComponent,
    NeedHelpLoggingInModalComponent,
    EnvironmentRibbonComponent,
    MomentPipe,
    YesNoPipe,
    VideoStreamModalComponent,
    DropDownComponent,
    DropDownMultiComponent,
    DropDownStakeholderWithIconComponent,
    NumberFormatPipe,
    ReloadFooterComponent,
    InfoBarComponent,
    MultipleSelectDropdownComponent,
  ],
  exports: [
    MaterialModule,
    SharedLibsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FindLanguageFromKeyPipe,
    FindLanguageFlagFromKeyPipe,
    TranslateDirective,
    AlertComponent,
    AlertErrorComponent,
    LoginModalComponent,
    HasAnyAuthorityDirective,
    InputPatternDirective,
    ErrorMessageComponent,
    InputComponent,
    InputCalendarComponent,
    InputAutoCompleteComponent,
    TextBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    PaginationComponent,
    DropDownInputComponent,
    ModalDialogComponent,
    SpinnerComponent,
    HeaderComponent,
    NavbarComponent,
    ActionBarComponent,
    FormErrorMessageComponent,
    FormControlErrorMessageComponent,
    InputDateTimeComponent,
    InputLocationComponent,
    MapComponent,
    SafeHtmlPipe,
    RouteTransformerDirective,
    OpenInvoicePdfComponent,
    TableauComponent,
    TermsOfUseComponent,
    TermsOfUseModalComponent,
    NeedHelpLoggingInModalComponent,
    EnvironmentRibbonComponent,
    MomentPipe,
    YesNoPipe,
    VideoStreamModalComponent,
    DropDownComponent,
    DropDownMultiComponent,
    DropDownStakeholderWithIconComponent,
    NumberFormatPipe,
    ReloadFooterComponent,
    InfoBarComponent,
    MultipleSelectDropdownComponent,
  ],
  providers: [
    FindLanguageFromKeyPipe,
    FindLanguageFlagFromKeyPipe
  ]
})
export class SharedModule {}
