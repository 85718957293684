export enum MedicalPreAssessmentStatusEnum {
  UPDATED = 'UPDATED',
  ONGOING = 'ONGOING',
  MEDICAL_EVALUATION = 'MEDICAL_EVALUATION',
  AWAITING = 'AWAITING',
  DONE = 'DONE'
}

export enum MedicalPreAssessmentDecisionEnum {
  PARTIALLY_COVERED = 'PARTIALLY_COVERED',
  FULLY_COVERED = 'FULLY_COVERED',
  CASE_HANDLING = 'CASE_HANDLING',
  REQUEST_INFORMATION = 'REQUEST_INFORMATION',
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  CANCELLED = 'CANCELLED'
}

export enum MedicalPreAssessmentTypeEnum {
  PREASSESSMENT_TRAVEL = 'PREASSESSMENT_TRAVEL',
  PREASSESSMENT_CANCELLATION = 'PREASSESSMENT_CANCELLATION'
}

export enum MedicalPreAssessmentRelevantEnum {
  YES = 'YES',
  NO = 'NO'
}

export enum MedicalAssessmentBasedOnEnum {
  MEDICAL_REPORT = 'MEDICAL_REPORT',
  VOICE = 'VOICE',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CASE_INFORMATION = 'CASE_INFORMATION',
  VIDEO_CALL = 'VIDEO_CALL'
}
