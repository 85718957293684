import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import localeDa from '@angular/common/locales/da';

import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgxWebstorageModule } from 'ngx-webstorage';
import dayjs from 'dayjs/esm';
import { NgbDateAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { SettingsLoaderService } from '@cupoui/core/services/settings.loader.service';
import { initializeApp } from '@cupoui/shared/app-init';
import { ApplicationConfigService } from './core/config/application-config.service';
import './config/dayjs';
import { SharedModule } from './shared/shared.module';
import { TranslationModule } from './shared/language/translation.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NgbDateDayjsAdapter } from './config/datepicker-adapter';
import { fontAwesomeIcons } from './config/font-awesome-icons';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { SidenavComponent } from './layouts/sidenav/sidenav.component';
import { NumberFormatPipe } from './shared/pipes/number-format.pipe';
import { DecimalPipe } from '@angular/common';
import { CaseRedirectorComponent } from './shared/redirector/case-redirector.component';
import { MainComponent } from './layouts/main/main.component';
import { AuthComponent } from './layouts/auth/auth.component';

registerLocaleData(localeDa);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    // Set this to true to enable service worker (PWA)
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    HttpClientModule,
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-', caseSensitive: true }),
    TranslationModule,
  ],
  providers: [
    Title,
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [SettingsLoaderService] },
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: NgbDateAdapter, useClass: NgbDateDayjsAdapter },
    DecimalPipe, NumberFormatPipe
  ],
  declarations: [
    AppComponent,
    AuthComponent,
    MainComponent,
    ErrorComponent,
    PageRibbonComponent,
    ActiveMenuDirective,
    FooterComponent,
    SidenavComponent,
    CaseRedirectorComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(applicationConfigService: ApplicationConfigService, iconLibrary: FaIconLibrary, dpConfig: NgbDatepickerConfig) {
//    const SERVER_API_URL = 'http://localhost:4200/'
//    applicationConfigService.setEndpointPrefix(SERVER_API_URL);
    registerLocaleData(locale);
    iconLibrary.addIcons(...fontAwesomeIcons);
    dpConfig.minDate = { year: dayjs().subtract(100, 'year').year(), month: 1, day: 1 };
  }
}
