import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@cupoui/core/services/http.service';
import { NGXLogger } from 'ngx-logger';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
@UntilDestroy({ checkProperties: true })
export class FooterComponent implements OnInit {
  onesosuiSettings: any;
  onesosuaaSettings: any;
  onesosGuidelineSettings: any;
  caseManagementSettings: any;
  caseEconomyServiceSettings: any;
  onePointSettings: any;
  documentServiceSettings: any;

  show = false;
  shown = false;

  constructor(private httpService: HttpService, private logger: NGXLogger) {}

  ngOnInit(): void {
    this.getCupouiSettings().subscribe(onesosuiSettings => (this.onesosuiSettings = onesosuiSettings));
    this.getOnesosuaaSettings().subscribe(onesosuaaSettings => (this.onesosuaaSettings = onesosuaaSettings));
    this.getOnesosGuidelineSettings()
      .subscribe(onesosGuidelineSettings => (this.onesosGuidelineSettings = onesosGuidelineSettings));
    this.getCaseManagementSettings()
      .subscribe(caseManagementSettings => (this.caseManagementSettings = caseManagementSettings));
    this.getCaseEconomyServiceSettings()
      .subscribe(caseEconomyServiceSettings => (this.caseEconomyServiceSettings = caseEconomyServiceSettings));
    this.getOnePointSettings().subscribe(onePointSettings => (this.onePointSettings = onePointSettings));
    this.getDocumentServiceSettings()
      .subscribe(documentServiceSettings => (this.documentServiceSettings = documentServiceSettings));
  }

  get footerStyle(): any {
    if (!this.shown) {
      return { left: '49%', right: 'inherit', height: '20px', top: 'calc(100vh - 20px)' };
    } else {
      return { left: '49%', right: 'inherit', height: '20px', top: 'calc(100vh - 63px)' };
    }
  }

  toggle(): void {
    this.shown = !this.shown;
  }

  private getCupouiSettings(): Observable<any> {
    return this.httpService.get<any>('api/app-info');
  }

  private getOnesosuaaSettings(): Observable<any> {
    return this.httpService.get<any>('services/onesosuaa/');
  }

  private getOnesosGuidelineSettings(): Observable<any> {
    return this.httpService.get<any>('services/onesosguideline/');
  }

  private getCaseManagementSettings(): Observable<any> {
    return this.httpService.get<any>('services/casemanagement/');
  }

  private getOnePointSettings(): Observable<any> {
    return this.httpService.get<any>('services/onepoint/');
  }

  private getCaseEconomyServiceSettings(): Observable<any> {
    return this.httpService.get<any>('services/caseeconomy/');
  }

  private getDocumentServiceSettings(): Observable<any> {
    return this.httpService.get<any>('services/documentservice/');
  }
}
