import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CustomerProfile {
  commonCustomerName: string | null = null;
  brandName: string | null = null;
  brandId: string | null = null;
  profileCode: string | null = null;
  profileName: string | null = null;
  profileId: string | null = null;
  teamName: string | null = null;
  adtCode: string | null = null;
  groupProfile: string | null = null;
  isGroupProfile: boolean;
  noInsuranceWithThisCustomerGroup = false;

  public constructor(init?: Partial<CustomerProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerProfilesAdapter implements Adapter<CustomerProfile> {
  adapt(item: any): CustomerProfile {
    return new CustomerProfile({
      commonCustomerName: item.commonCustomerName,
      brandName: item.brandName,
      brandId: item.brandId,
      profileName: item.profileName,
      profileId: item.profileId,
      profileCode: item.profileCode,
      teamName: item.teamName,
      adtCode: item.adtCode,
      groupProfile: item.groupProfile,
      isGroupProfile: item.isGroupProfile
    });
  }
}
