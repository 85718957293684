<app-modal-dialog [configuration]="modalDialogConfiguration">
    <div class="how-to-video-player" fxFlex="100">
        <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>

            <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>

            <vg-controls>
                <vg-play-pause></vg-play-pause>

                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

                <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

                <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

                <vg-mute></vg-mute>
                <vg-volume></vg-volume>

                <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video #media [vgMedia]="media" id="singleVideo" preload="auto" autoplay="true" height="100%" width="100%" [poster]="howToLoginVideoPosterUrl" crossorigin>
                <source [src]="howToLoginVideoUrl" type="video/mp4">
            </video>
        </vg-player>
    </div>
</app-modal-dialog>
