import { Injectable } from '@angular/core';
import { Icd10Code } from './icd10Code';
import {
  MedicalAssessmentBasedOnEnum,
  MedicalPreAssessmentDecisionEnum,
  MedicalPreAssessmentRelevantEnum,
  MedicalPreAssessmentStatusEnum,
  MedicalPreAssessmentTypeEnum
} from './enumerations/medical-preassessment-enum';

export class MedicalPreAssessment {
  id: number;
  seccaCaseId: number;
  sourceList: MedicalPreAssessmentSource[];
  medicalAssessmentType: MedicalPreAssessmentTypeEnum;
  questionnaires: Questionnaire[];
  note: MedicalPreAssessmentNote;
  decision: MedicalPreAssessmentDecisionEnum;
  type: MedicalPreAssessmentTypeEnum;
  status: MedicalPreAssessmentStatusEnum;
  decisionDetail: MedicalPreAssessmentDecisionDetail;

  public constructor(init?: Partial<MedicalPreAssessment>) {
    Object.assign(this, init);
    if (!init || !init.sourceList) {
      this.sourceList = new Array<MedicalPreAssessmentSource>();
      this.sourceList.push(new MedicalPreAssessmentSource());
    }

    if (!init || !init.questionnaires) {
      this.questionnaires = new Array<Questionnaire>();
      this.questionnaires.push(new Questionnaire());
    }

    if (!init || !init.note) {
      this.note = new MedicalPreAssessmentNote();
    }

    if (!init || !init.decisionDetail) {
      this.decisionDetail = new MedicalPreAssessmentDecisionDetail();
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentAdapter {
  adapt(data: any): MedicalPreAssessment {
    if (data === null) {
      return new MedicalPreAssessment();
    }
    return new MedicalPreAssessment({
      id: data.id,
      seccaCaseId: data.seccaCaseId,
      medicalAssessmentType: data.medicalAssessmentType,
      sourceList: !!data.sourceList ? this.adaptSourceList(data.sourceList) : undefined,
      questionnaires: !!data.questionnaires ? this.adaptQuestionnaireList(data.questionnaires) : undefined,
      note: !!data.note ? new MedicalPreAssessmentNoteAdapter().adapt(data.note) : undefined,
      status: data.status,
      decision: data.decision,
      decisionDetail:
        data.note.canFinalizeAssessment === true ? new MedicalPreAssessmentDecisionDetailAdapter().adapt(data.decisionDetail) : undefined
    });
  }

  adaptSourceList(data: any[]) {
    const sourceList = new Array<MedicalPreAssessmentSource>();
    data.forEach(item => sourceList.push(new MedicalPreAssessmentSourceAdapter().adapt(item)));

    return sourceList;
  }

  adaptQuestionnaireList(data: any[]) {
    const questionaireList = new Array<Questionnaire>();
    data.forEach(item => questionaireList.push(new MedicalPreAssessmentQuestionaireAdapter().adapt(item)));
    return questionaireList;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentSourceAdapter {
  adapt(data: any): MedicalPreAssessmentSource {
    return new MedicalPreAssessmentSource({
      id: data.id,
      basedOn: data.basedOn,
      sourceName: data.sourceName,
      sourceType: data.sourceType
    });
  }
}

export class Questionnaire {
  id: number;
  diagnosisPreDeparture: Icd10Code[];
  diagnosisReported: Icd10Code[];
  relevant: MedicalPreAssessmentRelevantEnum;
  recentOtherTreatments: boolean;
  recentMedicationChanges: boolean;
  recentTreatmentPlanChanges: boolean;
  awaitingRecentTreatmentResults: boolean;

  public constructor(init?: Partial<Questionnaire>) {
    Object.assign(this, init);
    if (!init || !init.diagnosisPreDeparture) {
      this.diagnosisPreDeparture = new Array<Icd10Code>();
    }
    if (!init || !init.diagnosisReported) {
      this.diagnosisReported = new Array<Icd10Code>();
    }
    if (!init || !init.relevant) {
      this.relevant = MedicalPreAssessmentRelevantEnum.YES;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentQuestionaireAdapter {
  adapt(data: any): Questionnaire {
    return new Questionnaire({
      id: data.id,
      diagnosisPreDeparture: new MedicalAssessmentIcd10Adapter().adapt(data.diagnosisPreDeparture),
      diagnosisReported: new MedicalAssessmentIcd10Adapter().adapt(data.diagnosisReported),
      relevant: new MedicalPreAssessmentRelevantEnumAdapter().adapt(data.relevant),
      recentOtherTreatments: data.recentOtherTreatments,
      recentMedicationChanges: data.recentMedicationChanges,
      recentTreatmentPlanChanges: data.recentTreatmentPlanChanges,
      awaitingRecentTreatmentResults: data.awaitingRecentTreatmentResults
    });
  }
}

export class MedicalPreAssessmentDecision {
  isCovered: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentDecision>) {
    Object.assign(this, init);
    if (!init || !init.isCovered) {
      this.isCovered = false;
    }
  }
}

export class MedicalPreAssessmentNote {
  id: number;
  evaluation: string;
  internalNote: string;
  canFinalizeAssessment: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentNote>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentNoteAdapter {
  adapt(data: any): MedicalPreAssessmentNote {
    return new MedicalPreAssessmentNote({
      id: data.id,
      evaluation: data.evaluation,
      internalNote: data.internalNote,
      canFinalizeAssessment: data.canFinalizeAssessment
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentIcd10Adapter {
  adapt(data: any): Icd10Code[] {
    const adapted = new Array<Icd10Code>();
    if (!!data && data.length > 0) {
      data.forEach((value: any) => adapted.push(value));
    }
    return adapted;
  }
  adaptItems(data: any): Icd10Code {
    const adapted = new Array<Icd10Code>();
    return new Icd10Code({
      icd10Code: data.icd10Code,
      shortName: data.shortName,
      selected: true
    });
  }
}

export class MedicalPreAssessmentDecisionDetail {
  id: string;
  templateName: string;
  templateLanguage: string;
  templateId: string;
  endUserDescription: string;
  evaluationDecision: string;
  public constructor(init?: Partial<MedicalPreAssessmentDecisionDetail>) {
    Object.assign(this, init);
  }
}

export class MedicalPreAssessmentDecisionDetailAdapter {
  adapt(data: any): MedicalPreAssessmentDecisionDetail {
    return new MedicalPreAssessmentDecisionDetail({
      id: data.id,
      templateName: data.templateName,
      templateLanguage: data.templateLanguage,
      templateId: data.templateId,
      endUserDescription: data.endUserDescription,
      evaluationDecision: data.evaluationDecision
    });
  }
}

export class MedicalPreAssessmentRelevantEnumAdapter {
  adapt(data: any): MedicalPreAssessmentRelevantEnum {
    if (!data) {
      return MedicalPreAssessmentRelevantEnum.YES;
    }
    return data;
  }
}

export class MedicalPreAssessmentSource {
  id: number;
  basedOn: MedicalAssessmentBasedOnEnum;
  sourceName: string;
  sourceType: string;

  public constructor(init?: Partial<MedicalPreAssessmentSource>) {
    Object.assign(this, init);
  }
}
