import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivateService {
  constructor(private http: HttpClient) {}

  get(key: string): Observable<{}> {
    return this.http.get('services/onesosuaa/api/activate', {
      params: new HttpParams().set('key', key)
    });
  }
}
