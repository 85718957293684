import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Icd10Code {
  icd10Code: string;
  shortName: any;
  selected: boolean;

  public constructor(init?: Partial<Icd10Code>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class Icd10CodeAdapter implements Adapter<Icd10Code> {
  adapt(item: any, selected: boolean = false): Icd10Code {
    return new Icd10Code({
      icd10Code: item.icd10Code,
      shortName: item.shortName,
      selected
    });
  }

  adaptItems(items: any[], selected: boolean = false): Icd10Code[] {
    return items.map(item => this.adapt(item, selected));
  }
}
