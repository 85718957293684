<div class="footer-container" [ngStyle]="footerStyle" (click)="toggle()">
    <div class="footer-show" ngbTooltip="Show environment params" placement="top" *ngIf="!shown"><fa-icon icon="chevron-up" size="lg"></fa-icon></div>
    <div class="footer-show" ngbTooltip="Hide environment params" placement="top" *ngIf="shown"><fa-icon icon="chevron-down" size="lg"></fa-icon></div>
</div>
<div class="footer-container" *ngIf="shown">
    <div class="footer-content" fxLayout="row" *ngIf="shown">
        <div class="info-group info-spacing" fxLayout="column" popoverTitle="Last commit {{onesosuiSettings.gitCommitTime | date: 'dd-MM-yyyy HH:mm:ss'}}" [ngbPopover]="onesosuiSettings.gitCommitMessage" triggers="mouseenter:mouseleave" *ngIf="onesosuiSettings">
            <div class="info-name">{{onesosuiSettings.buildName}}</div>
            <div class="info-value">{{onesosuiSettings.buildVersion}}</div>
        </div>

        <div class="info-group info-spacing" fxLayout="column" popoverTitle="Last commit {{onesosuaaSettings.gitCommitTime | date: 'dd-MM-yyyy HH:mm:ss'}}" [ngbPopover]="onesosuaaSettings.gitCommitMessage" triggers="mouseenter:mouseleave" *ngIf="onesosuaaSettings">
            <div class="info-name">{{onesosuaaSettings.buildName}}</div>
            <div class="info-value">{{onesosuaaSettings.buildVersion}}</div>
        </div>

        <div class="info-group info-spacing" fxLayout="column" popoverTitle="Last commit {{onesosGuidelineSettings.gitCommitTime | date: 'dd-MM-yyyy HH:mm:ss'}}" [ngbPopover]="onesosGuidelineSettings.gitCommitMessage" triggers="mouseenter:mouseleave" *ngIf="onesosGuidelineSettings">
            <div class="info-name">{{onesosGuidelineSettings.buildName}}</div>
            <div class="info-value">{{onesosGuidelineSettings.buildVersion}}</div>
        </div>

        <ng-container *ngIf="caseManagementSettings">
            <div class="info-group info-spacing" fxLayout="column" [ngbTooltip]="caseManagementSettings.gitCommitMessage" placement="top">
                <div class="info-name">{{caseManagementSettings.name}}</div>
                <div class="info-value">{{caseManagementSettings.version}}</div>
            </div>
        </ng-container>

        <ng-container *ngIf="caseEconomyServiceSettings">
            <div class="info-group info-spacing" fxLayout="column" [ngbTooltip]="caseEconomyServiceSettings.gitCommitMessage" placement="top">
                <div class="info-name">{{caseEconomyServiceSettings.name}}</div>
                <div class="info-value">{{caseEconomyServiceSettings.version}}</div>
            </div>
        </ng-container>

        <ng-container *ngIf="onePointSettings">
            <div class="info-group info-spacing" fxLayout="column" [ngbTooltip]="onePointSettings.gitCommitMessage" placement="top">
                <div class="info-name">{{onePointSettings.name}}</div>
                <div class="info-value">{{onePointSettings.version}}</div>
            </div>
        </ng-container>

        <ng-container *ngIf="documentServiceSettings">
            <div class="info-group info-spacing" fxLayout="column" [ngbTooltip]="documentServiceSettings.gitCommitMessage" placement="top">
                <div class="info-name">{{documentServiceSettings.name}}</div>
                <div class="info-value">{{documentServiceSettings.version}}</div>
            </div>
        </ng-container>
    </div>
</div>
