import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, of, BehaviorSubject } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import { HttpService } from '@cupoui/core/services/http.service';
import { StateStorageService } from '@cupoui/core/auth/state-storage.service';
import { Account } from '@cupoui/core/user/account.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;

  private _languageChanged = new BehaviorSubject<string>('');
  public readonly languageChanged$ = this._languageChanged.asObservable();

  constructor(
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private httpService: HttpService,
    private stateStorageService: StateStorageService,
    private router: Router
  ) {}

  save(account: Account): Observable<{}> {
    return this.httpService.post('services/onesosuaa/api/account', account);
  }

  languageChanged(languageKey: string): void {
    this._languageChanged.next(languageKey);
  }

  requestOneTimePin(mail: string): Observable<{}> {
    return this.httpService.post('services/onesosuaa/api/pin-send-by-mail', mail);
  }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) => authorities.includes(authority));
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => {
          return of(null);
        }),
        tap((account: Account | null) => {
          this.authenticate(account);

          // After retrieve the account info, the language will be changed to
          // the user's preferred language configured in the account setting
          if (account && account.langKey) {
            const langKey = this.sessionStorage.retrieve('locale') || account.langKey;
            this.translateService.use(langKey);
          }

          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  getLogin(): string {
    return this.userIdentity ? this.userIdentity.login : '';
  }

  getFirstName(): string {
    return this.userIdentity ? this.userIdentity.firstName : '';
  }

  getFullName(): string {
    return this.userIdentity ? `${this.userIdentity.firstName} ${this.userIdentity.lastName}` : '';
  }

  getImageUrl(): string {
    return this.userIdentity ? this.userIdentity.imageUrl : '';
  }

  getCustomerProfiles(): string[] {
    return this.userIdentity ? this.userIdentity.customerProfiles : [];
  }

  getCustomerRoles(): string[] {
    return this.userIdentity ? this.userIdentity.customerRoles : [];
  }

  private fetch(): Observable<Account> {
    return this.httpService.get<Account>('services/onesosuaa/api/account');
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    } else if (this.router.url === '/auth/sign-in') {
      this.router.navigate(['']);
    }
  }
}
