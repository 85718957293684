import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Country } from './country';

export class Address {
  id: number;
  region: string;
  postalCode: string;
  city: string;
  street: string;
  countryId: string;
  longitude: number;
  latitude: number;
  timezone: number;
  homeCountryCode: string;
  homeCountry: string;
  country: Country;

  public constructor(init?: Partial<Address>) {
    Object.assign(this, init);
    if (!init || !init.country) {
      this.country = new Country();
      this.country.code = null;
      this.country.countryName = '';
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AddressAdapter implements Adapter<Address> {
  adapt(item: any): Address {
    return new Address({
      id: item.id,
      region: item.region,
      postalCode: item.postalCode,
      street: item.street,
      countryId: item.countryId,
      city: item.city,
      latitude: item.latitude,
      longitude: item.longitude,
      homeCountryCode: item.homeCountryCode,
      homeCountry: item.homeCountry,
      country: item.country
    });
  }
}
