import { Component } from '@angular/core';

@Component({
  selector: 'jhi-alert-error',
  template: `
    <div class="alerts" role="alert">
    </div>
  `
})
export class AlertErrorComponent {
}
