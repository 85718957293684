import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Location } from './location';

export class PlannedTravel {
  id: string;
  seccaCaseId: string;
  destinationLocation: Location;
  departureDate: Date | null;
  arrivalDate: Date | null;
  purposesOfTravel: string[];
  meansOfTransportation: string;
  accommodation: string;
  coTravellers: string;
  departureAndArrival: (Date | null)[] | null;
  durationDays: string;
  durationWeeks: string;
  travelAgency: string;
  expatriationLocation: Location;
  expatriationDepartureDate: Date | null;
  expatriationArrivalDate: Date | null;
  expatriationDepartureAndArrival: (Date | null)[] | null;

  public constructor(init?: Partial<PlannedTravel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlannedTravelAdapter implements Adapter<PlannedTravel> {
  adapt(item: any): PlannedTravel {
    let itemDestinationLocation = null;
    if (!item.destinationLocation) {
      itemDestinationLocation = new Location();
    } else {
      itemDestinationLocation = new Location({
        id: item.destinationLocation.id,
        freeText: item.destinationLocation.freeText,
        streetNumber: item.destinationLocation.streetNumber,
        streetName: item.destinationLocation.streetName,
        locality: item.destinationLocation.locality,
        administrativeAreaLevel1: item.destinationLocation.administrativeAreaLevel1,
        administrativeAreaLevel2: item.destinationLocation.administrativeAreaLevel2,
        country: item.destinationLocation.country,
        postalCode: item.destinationLocation.postalCode,
        latitude: item.destinationLocation.latitude,
        longitude: item.destinationLocation.longitude
      });
    }

    var itemExpatriationLocation = null;
    if (!item.expatriationLocation) {
      itemExpatriationLocation = new Location();
    } else {
      itemExpatriationLocation = new Location({
        id: item.expatriationLocation.id,
        freeText: item.expatriationLocation.freeText,
        streetNumber: item.expatriationLocation.streetNumber,
        streetName: item.expatriationLocation.streetName,
        locality: item.expatriationLocation.locality,
        administrativeAreaLevel1: item.expatriationLocation.administrativeAreaLevel1,
        administrativeAreaLevel2: item.expatriationLocation.administrativeAreaLevel2,
        country: item.expatriationLocation.country,
        postalCode: item.expatriationLocation.postalCode,
        latitude: item.expatriationLocation.latitude,
        longitude: item.expatriationLocation.longitude
      });
    }

    const departureDate = !item.departureDate ? null : new Date(item.departureDate);
    const arrivalDate = !item.arrivalDate ? null : new Date(item.arrivalDate);
    const expatriationDepartureDate = !item.expatriationDepartureDate ? null : new Date(item.expatriationDepartureDate);
    const expatriationArrivalDate = !item.expatriationArrivalDate ? null : new Date(item.expatriationArrivalDate);

    return new PlannedTravel({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      destinationLocation: itemDestinationLocation,
      departureDate,
      arrivalDate,
      departureAndArrival: [departureDate, arrivalDate],
      purposesOfTravel: item.purposesOfTravel ? item.purposesOfTravel : [],
      meansOfTransportation: item.meansOfTransportation,
      accommodation: item.accommodation,
      coTravellers: item.coTravellers,
      durationDays: item.durationDays,
      durationWeeks: item.durationWeeks,
      travelAgency: item.travelAgency,
      expatriationLocation: itemExpatriationLocation,
      expatriationDepartureDate,
      expatriationArrivalDate,
      expatriationDepartureAndArrival: [expatriationDepartureDate, expatriationArrivalDate]
    });
  }
}
