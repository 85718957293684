import { Injectable } from '@angular/core';
import { Adapter } from '@cupoui/shared/interfaces/adapter';
import { IUserInternal } from '@cupoui/shared/model/user';
import { HandlingAreaStatusEnum } from '@cupoui/shared/model/enumerations/handling-area-status.enum';
import { HandlingAreaTypeEnum } from '@cupoui/shared/model/enumerations/handling-area-type.enum';

export class HandlingArea {
  status: HandlingAreaStatusEnum;
  handlingAreaType: HandlingAreaTypeEnum;
  team: { id: null; name: null; teamId: string; caseHandler: boolean };
  user: IUserInternal;
  id: number;

  public constructor(init?: Partial<HandlingArea>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class HandlingAreaAdapter implements Adapter<HandlingArea> {
  adapt(item: any): HandlingArea {
    return new HandlingArea({
      handlingAreaType: item.handlingAreaType,
      team: item.team,
      status: item.status,
      user: item.user,
      id: item.id
    });
  }
}
