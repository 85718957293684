import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@cupoui/core/services/http.service';
import { CustomerNameHolder, CustomerNameAdapter } from '../../shared/model/customerProfiles';
import { SERVER_API_URL } from '../../app.constants';
import { PlannedTravelAdapter, PlannedTravel } from '@cupoui/shared/model/planned-travel';
import { Case, CaseAdapter } from '@cupoui/shared/model/case';
import { CaseStakeholder, CaseStakeholderAdapter } from '@cupoui/shared/model/caseStakeholder';
import { ICaseCustomerNote } from '@cupoui/shared/model/case-customer-note';
import { ICaseDocument } from '@cupoui/shared/model/case-document';
import { ICaseRegularMedicalAssessment, ICaseMedicalHistory, MedicalHistoryType } from '@cupoui/shared/model/case-medical';
import { Dictionary } from '@cupoui/shared/model/dictionary';
import { IServiceOrder } from '@cupoui/shared/model/service-order';
import { ICoverageType } from '@cupoui/shared/model/coverage-type';
import { CustomerProfile, CustomerProfilesAdapter } from '@cupoui/shared/model/customer-profile';
import { MedicalPreAssessment, MedicalPreAssessmentAdapter } from '../../shared/model/medicalPreAssessment';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  constructor(
    private httpService: HttpService,
    private customerNameAdapter: CustomerNameAdapter,
    private plannedTravelAdapter: PlannedTravelAdapter,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private customerProfilesAdapter: CustomerProfilesAdapter,
    private caseAdapter: CaseAdapter,
    private medicalPreAssessmentAdapter: MedicalPreAssessmentAdapter
  ) {}

  public getCase(id: string): Observable<Case> {
    return this.httpService.get(`services/casemanagement/api/cases/${id}`).pipe(map(item => this.caseAdapter.adapt(item)));
  }

  public getStakeholderForCase(caseId: string, type: string): Observable<CaseStakeholder> {
    return this.httpService
      .get(`services/casemanagement/api/cases/${caseId}/stakeholders/type/${type}`)
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getStakeholderById(id: string): Observable<CaseStakeholder> {
    return this.httpService
      .get(`services/casemanagement/api/stakeholders/${id}`)
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getCaseDocuments(caseId: string): Observable<ICaseDocument[]> {
    return this.httpService
      .get<ICaseDocument[]>(`services/casemanagement/api/documents/case/${caseId}`)
      .pipe(map((data: ICaseDocument[]) => data.filter(item => !item.unhandled && item.caseDocumentTags.length)));
  }

  public getCaseRegularMedicalAssessment(caseId: string): Observable<ICaseRegularMedicalAssessment[]> {
    return this.httpService.get<ICaseRegularMedicalAssessment[]>(`services/casemanagement/api/medical-assessment/case/${caseId}`);
  }

  public getCaseMedicalPreAssessment(caseId: string): Observable<MedicalPreAssessment[]> {
    return this.httpService.get<MedicalPreAssessment[]>(`services/casemanagement/api/medical-pre-assessment/case/${caseId}`);
  }

  public getNewestMedicalPreAssessment(caseId: number): Observable<MedicalPreAssessment> {
    return this.httpService
      .get(`services/casemanagement/api/medical-pre-assessment/case/newest/${caseId}`)
      .pipe(map(item => this.medicalPreAssessmentAdapter.adapt(item)));
  }

  public getCaseMedicalHistory(caseId: string): Observable<ICaseMedicalHistory[]> {
    return this.httpService
      .get<ICaseMedicalHistory[]>(`services/casemanagement/api/medical-history/case/${caseId}`)
      .pipe(map((data: ICaseMedicalHistory[]) => data.filter(item => item.medicalHistoryType !== MedicalHistoryType.medicalComment)));
  }

  public getServiceOrders(caseId: string): Observable<IServiceOrder[]> {
    return this.httpService.get<IServiceOrder[]>(`services/casemanagement/api/service-orders/by-case/${caseId}`);
  }

  public getCustomerProfile(profileId: string): Observable<CustomerNameHolder> {
    return this.httpService
      .get<CustomerNameHolder>(`services/onepoint/api/profile/${profileId}`)
      .pipe(map(item => this.customerNameAdapter.adapt(item)));
  }

  public getPlannedTravel(caseId: string): Observable<PlannedTravel> {
    return this.httpService
      .get<any>(`services/casemanagement/api/travelplans/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.plannedTravelAdapter.adapt(item))[0]));
  }

  public getPlannedTravelList(caseId: string): Observable<PlannedTravel[]> {
    return this.httpService
      .get<any>(`services/casemanagement/api/travelplans/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.plannedTravelAdapter.adapt(item))));
  }

  public getCustomerNotes(caseId: string): Observable<ICaseCustomerNote[]> {
    return this.httpService.get<ICaseCustomerNote[]>(`services/casemanagement/api/notes/by-entity-id/${caseId}`);
  }

  public addCustomerNote(customerNote: Partial<ICaseCustomerNote>): Observable<void> {
    return this.httpService.post<void>('services/casemanagement/api/notes', customerNote);
  }

  public getBrandImagesUrlForProfile(profile: string): string {
    // return SERVER_API_URL + `services/onepoint/api/profile/${profile}/brand-icon`;
    return SERVER_API_URL + `api/profile/${profile}/brand-icon`;
  }

  public getProfileProducts(profileId: number): Observable<Dictionary[]> {
    return this.httpService
      .get<Dictionary[]>(`services/onepoint/api/products/profile/${profileId}?activeOnly=true`)
      .pipe(map((data: any[]) => data?.map(item => new Dictionary(item.id, item.name))));
  }

  public getCaseStakeholdersOnCase(caseId: string): Observable<CaseStakeholder[]> {
    return this.httpService
      .get<any[]>(`services/casemanagement/api/cases/${caseId}/stakeholders`)
      .pipe(map((data: CaseStakeholder[]) => data.map(item => this.caseStakeholderAdapter.adapt(item))));
  }

  public getValidCaseCoverages(caseId: string): Observable<ICoverageType[]> {
    return this.httpService.get<ICoverageType[]>(`services/casemanagement/api/service-orders/case-coverages/${caseId}`);
  }

  public getAllCustomerProfiles(): Observable<CustomerProfile[]> {
    return this.httpService
      .get<CustomerProfile[]>(SERVER_API_URL + `services/casemanagement/api/profile/all-customer-travel-profiles/`)
      .pipe(map((data: any[]) => data.map(item => this.customerProfilesAdapter.adapt(item))));
  }

  public getCustomerProfilesByCase(caseId: string): Observable<CustomerProfile[]> {
    return this.httpService
      .get<CustomerProfile[]>(SERVER_API_URL + `services/casemanagement/api/customer-profiles/all/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.customerProfilesAdapter.adapt(item))));
  }
}
