import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogConfiguration } from '@cupoui/shared/model/modal/modal-dialog-configuration';

@Component({
  selector: 'app-need-help-logging-in-modal',
  templateUrl: './need-help-logging-in-modal.component.html',
  styleUrls: ['./need-help-logging-in-modal.component.scss']
})
export class NeedHelpLoggingInModalComponent implements OnInit {
  modalDialogConfiguration: ModalDialogConfiguration;

  howToLoginVideoUrl: string;
  howToLoginVideoPosterUrl: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.modalDialogConfiguration = new ModalDialogConfiguration({
      header: `Need help logging in?`
    });
    this.howToLoginVideoUrl = `${SERVER_API_URL}video/stream/How to login to Customer Portal.mp4`;
    this.howToLoginVideoPosterUrl = `${SERVER_API_URL}video/thumbnail/how to login.png`;
  }
}
