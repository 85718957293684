import { Component, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() size = 'lg';
  @Input() fxLayoutAlign = 'center';
  @Input() noPadding = false;

  isDecember: boolean;
  isChristmasPeriod: boolean;
  isJanuary: boolean;
  isSkiingPeriod: boolean;

  constructor() {
    this.isDecember = moment().month() === 11;
    this.isChristmasPeriod = this.isDecember && moment().date() >= 23 && moment().date() <= 26;
    this.isJanuary = moment().month() === 0;
    this.isSkiingPeriod = moment().month() === 1;
  }
}
