import { Injectable } from '@angular/core';
import { RoleService } from '../user/role.service';
import { IRole } from '../user/role.model';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from './account.service';
import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Account } from '../user/account.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@UntilDestroy({ checkProperties: true })
export class AccountRoleService {
  private userRoles: IRole = {};
  private roleState = new ReplaySubject<IRole>();
  private authSubscription: Subscription;

  constructor(private roleService: RoleService, private accountService: AccountService, private logger: NGXLogger) {
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => {
      if (account != null) {
        this.loadMyRoles().subscribe(allRoles => {
          this.initUserRoles(account, allRoles);
          this.roleState.next(this.roles);
        });
      } else {
        this.roleState.next({});
        this.roleState.complete();
        this.roleState = new ReplaySubject<IRole>();
      }
    });
  }

  public getRoleState(): Observable<IRole> {
    return this.roleState.asObservable();
  }

  public isCasesActive(): boolean {
    return !!this.userRoles.canViewTravelCases || !!this.userRoles.canViewMobilityCases || !!this.userRoles.canViewHealthCases;
  }

  public isInsightsActive(): boolean {
    return (
      !!this.userRoles.canAccessInsightsPage &&
      (!!this.userRoles.canAccessInsightsPageForTravel ||
        !!this.userRoles.canAccessInsightsPageForMobility ||
        !!this.userRoles.canAccessInsightsPageForHealth)
    );
  }

  public isEconomyActive(): boolean {
    return (
      !!this.userRoles.canAccessEconomyPage &&
      (!!this.userRoles.canAccessEconomyPageForTravel ||
        !!this.userRoles.canAccessEconomyPageForMobility ||
        !!this.userRoles.canAccessEconomyPageForHealth)
    );
  }

  public isProductsAndContractsActive(): boolean {
    return (
      !!this.userRoles.canAccessProductsAndContractsPage ||
      !!this.userRoles.canAccessProductsAndContractsPageForTravel ||
      !!this.userRoles.canAccessProductsAndContractsPageForMobility ||
      !!this.userRoles.canAccessProductsAndContractsPageForHealth
    );
  }

  public isMajorIncidentsActive(): boolean {
    return (
      !!this.userRoles.canAccessMajorIncidentsPage ||
      !!this.userRoles.canAccessMajorIncidentsPageForTravel ||
      !!this.userRoles.canAccessMajorIncidentsPageForMobility ||
      !!this.userRoles.canAccessMajorIncidentsPageForHealth
    );
  }

  public isAllowed(...accesstypes: string[]): boolean {
    for (const accessType of accesstypes) {
      if (this.userRoles[accessType]) {
        return true;
      }
    }

    return false;
  }

  public get roles(): IRole {
    return this.userRoles;
  }

  //
  //
  //

  private loadMyRoles(): Observable<IRole[]> {
    return this.roleService.myCustomerRoles().pipe(
      tap(response => this.logger.debug('My user roles:', response)),
      catchError(err => {
        this.logger.debug(err);
        throw err;
      })
    );
  }

  private initUserRoles(account: Account | null, allRoles: IRole[]): void {
    this.userRoles = {};

    if (account != null) {
      account.customerRoles?.forEach(roleName => {
        const role = allRoles?.find(r => r.customerRoleName === roleName);
        if (role) {
          Object.keys(role).forEach(key => {
            if (role[key]) {
              this.userRoles[key] = role[key];
            }
          });
        }
      });
    }

    this.logger.debug('Computed roles for user:', this.userRoles);
  }
}
