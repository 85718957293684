import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-environment-ribbon',
  templateUrl: './environment-ribbon.component.html',
  styleUrls: ['./environment-ribbon.component.scss']
})
export class EnvironmentRibbonComponent implements OnInit {
  @Input() isAuthenticated = true;

  environmentRibbonText: string;
  environmentRibbonBackground: string;

  constructor() {}

  ngOnInit(): void {
    const serverUrl = window.location.host;
    if (serverUrl.includes('localhost')) {
      this.environmentRibbonText = 'local';
      this.environmentRibbonBackground = 'local';
    } else if (serverUrl.includes('-')) {
      let env = serverUrl.substr(serverUrl.lastIndexOf('-'));
      env = env.substr(1, env.indexOf('.') - 1);
      this.environmentRibbonText = env;
      this.environmentRibbonBackground = env;
    }
  }
}
