import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SessionStorageService } from 'ngx-webstorage';

export function appInitializerFactory(translateService: TranslateService): () => Promise<any> {
  return () => {
    translateService.setDefaultLang('en');
    return translateService.use('en').toPromise();
  };
}

@NgModule({
  imports: [
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: translatePartialLoader,
    //     deps: [HttpClient],
    //   },
    //   missingTranslationHandler: {
    //     provide: MissingTranslationHandler,
    //     useFactory: missingTranslationHandler,
    //   },
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ]
})
export class TranslationModule {
  constructor(private translateService: TranslateService, sessionStorageService: SessionStorageService) {
    translateService.setDefaultLang('en');
    // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    const langKey = sessionStorageService.retrieve('locale') ?? 'en';
    translateService.use(langKey);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  const suf = '.json?' + new Date().getTime();
  return new TranslateHttpLoader(http, '/content/i18n/', encodeURI(suf));
}
