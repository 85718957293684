<div class="activate-container">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <h3 jhiTranslate="activate.title">Activation</h3>

            <div class="alert alert-success" *ngIf="success">
                <span jhiTranslate="activate.messages.success"><strong>Your user account has been activated.</strong> Please </span>
                <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a>.
            </div>

            <div class="alert alert-danger" *ngIf="error" jhiTranslate="activate.messages.error">
                <strong>Your user could not be activated.</strong> Please use the registration form to sign up.
            </div>
        </div>
    </div>
</div>
